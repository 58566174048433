var exports = {};

/**
 * Utility method for determining whether or not the value returned
 * from a handler indicates that it was handled.
 */
function isEventHandled(value) {
  return value === "handled" || value === true;
}

exports = isEventHandled;
export default exports;