import _UserAgent from "fbjs/lib/UserAgent";
import _editOnBeforeInput from "./editOnBeforeInput";
import _editOnBlur from "./editOnBlur";
import _editOnCompositionStart from "./editOnCompositionStart";
import _editOnCopy from "./editOnCopy";
import _editOnCut from "./editOnCut";
import _editOnDragOver from "./editOnDragOver";
import _editOnDragStart from "./editOnDragStart";
import _editOnFocus from "./editOnFocus";
import _editOnInput from "./editOnInput";
import _editOnKeyDown from "./editOnKeyDown";
import _editOnPaste from "./editOnPaste";
import _editOnSelect from "./editOnSelect";
var exports = {};
var UserAgent = _UserAgent;
var onBeforeInput = _editOnBeforeInput;
var onBlur = _editOnBlur;
var onCompositionStart = _editOnCompositionStart;
var onCopy = _editOnCopy;
var onCut = _editOnCut;
var onDragOver = _editOnDragOver;
var onDragStart = _editOnDragStart;
var onFocus = _editOnFocus;
var onInput = _editOnInput;
var onKeyDown = _editOnKeyDown;
var onPaste = _editOnPaste;
var onSelect = _editOnSelect;
var isChrome = UserAgent.isBrowser("Chrome");
var isFirefox = UserAgent.isBrowser("Firefox");
var selectionHandler = isChrome || isFirefox ? onSelect : function (e) {};
var DraftEditorEditHandler = {
  onBeforeInput: onBeforeInput,
  onBlur: onBlur,
  onCompositionStart: onCompositionStart,
  onCopy: onCopy,
  onCut: onCut,
  onDragOver: onDragOver,
  onDragStart: onDragStart,
  onFocus: onFocus,
  onInput: onInput,
  onKeyDown: onKeyDown,
  onPaste: onPaste,
  onSelect: onSelect,
  // In certain cases, contenteditable on chrome does not fire the onSelect
  // event, causing problems with cursor positioning. Therefore, the selection
  // state update handler is added to more events to ensure that the selection
  // state is always synced with the actual cursor positions.
  onMouseUp: selectionHandler,
  onKeyUp: selectionHandler
};
exports = DraftEditorEditHandler;
export default exports;