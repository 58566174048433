import _EditorState from "./EditorState";
import _UnicodeUtils from "fbjs/lib/UnicodeUtils";
import _moveSelectionBackward from "./moveSelectionBackward";
import _removeTextWithStrategy from "./removeTextWithStrategy";
var exports = {};
var EditorState = _EditorState;
var UnicodeUtils = _UnicodeUtils;
var moveSelectionBackward = _moveSelectionBackward;
var removeTextWithStrategy = _removeTextWithStrategy;
/**
 * Remove the selected range. If the cursor is collapsed, remove the preceding
 * character. This operation is Unicode-aware, so removing a single character
 * will remove a surrogate pair properly as well.
 */

function keyCommandPlainBackspace(editorState) {
  var afterRemoval = removeTextWithStrategy(editorState, function (strategyState) {
    var selection = strategyState.getSelection();
    var content = strategyState.getCurrentContent();
    var key = selection.getAnchorKey();
    var offset = selection.getAnchorOffset();
    var charBehind = content.getBlockForKey(key).getText()[offset - 1];
    return moveSelectionBackward(strategyState, charBehind ? UnicodeUtils.getUTF16Length(charBehind, 0) : 1);
  }, "backward");

  if (afterRemoval === editorState.getCurrentContent()) {
    return editorState;
  }

  var selection = editorState.getSelection();
  return EditorState.push(editorState, afterRemoval.set("selectionBefore", selection), selection.isCollapsed() ? "backspace-character" : "remove-range");
}

exports = keyCommandPlainBackspace;
export default exports;