import _DraftModifier from "./DraftModifier";
import _EditorState from "./EditorState";
import _Style from "fbjs/lib/Style";
import _getFragmentFromSelection from "./getFragmentFromSelection";
import _getScrollPosition from "fbjs/lib/getScrollPosition";
import _isInstanceOfNode from "./isInstanceOfNode";
var exports = {};
var DraftModifier = _DraftModifier;
var EditorState = _EditorState;
var Style = _Style;
var getFragmentFromSelection = _getFragmentFromSelection;
var getScrollPosition = _getScrollPosition;
var isNode = _isInstanceOfNode;
/**
 * On `cut` events, native behavior is allowed to occur so that the system
 * clipboard is set properly. This means that we need to take steps to recover
 * the editor DOM state after the `cut` has occurred in order to maintain
 * control of the component.
 *
 * In addition, we can keep a copy of the removed fragment, including all
 * styles and entities, for use as an internal paste.
 */

function editOnCut(editor, e) {
  var editorState = editor._latestEditorState;
  var selection = editorState.getSelection();
  var element = e.target;
  var scrollPosition; // No selection, so there's nothing to cut.

  if (selection.isCollapsed()) {
    e.preventDefault();
    return;
  } // Track the current scroll position so that it can be forced back in place
  // after the editor regains control of the DOM.


  if (isNode(element)) {
    var node = element;
    scrollPosition = getScrollPosition(Style.getScrollParent(node));
  }

  var fragment = getFragmentFromSelection(editorState);
  editor.setClipboard(fragment); // Set `cut` mode to disable all event handling temporarily.

  editor.setMode("cut"); // Let native `cut` behavior occur, then recover control.

  setTimeout(function () {
    editor.restoreEditorDOM(scrollPosition);
    editor.exitCurrentMode();
    editor.update(removeFragment(editorState));
  }, 0);
}

function removeFragment(editorState) {
  var newContent = DraftModifier.removeRange(editorState.getCurrentContent(), editorState.getSelection(), "forward");
  return EditorState.push(editorState, newContent, "remove-range");
}

exports = editOnCut;
export default exports;