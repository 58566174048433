import _EditorState from "./EditorState";
var exports = {};
var EditorState = _EditorState;
/**
 * The user has begun using an IME input system. Switching to `composite` mode
 * allows handling composition input and disables other edit behavior.
 */

function editOnCompositionStart(editor, e) {
  editor.setMode("composite");
  editor.update(EditorState.set(editor._latestEditorState, {
    inCompositionMode: true
  })); // Allow composition handler to interpret the compositionstart event

  editor._onCompositionStart(e);
}

exports = editOnCompositionStart;
export default exports;