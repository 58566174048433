var exports = {};

/**
 * A `dragstart` event has begun within the text editor component.
 */
function editOnDragStart(editor) {
  editor._internalDrag = true;
  editor.setMode("drag");
}

exports = editOnDragStart;
export default exports;