var exports = {};

function isSelectionAtLeafStart(editorState) {
  var selection = editorState.getSelection();
  var anchorKey = selection.getAnchorKey();
  var blockTree = editorState.getBlockTree(anchorKey);
  var offset = selection.getStartOffset();
  var isAtStart = false;
  blockTree.some(function (leafSet) {
    if (offset === leafSet.get("start")) {
      isAtStart = true;
      return true;
    }

    if (offset < leafSet.get("end")) {
      return leafSet.get("leaves").some(function (leaf) {
        var leafStart = leaf.get("start");

        if (offset === leafStart) {
          isAtStart = true;
          return true;
        }

        return false;
      });
    }

    return false;
  });
  return isAtStart;
}

exports = isSelectionAtLeafStart;
export default exports;