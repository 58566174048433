import _DraftModifier from "./DraftModifier";
import _EditorState from "./EditorState";
import _getContentStateFragment from "./getContentStateFragment";
import _nullthrows from "fbjs/lib/nullthrows";
var exports = {};
var DraftModifier = _DraftModifier;
var EditorState = _EditorState;
var getContentStateFragment = _getContentStateFragment;
var nullthrows = _nullthrows;
var clipboard = null;
/**
 * Some systems offer a "secondary" clipboard to allow quick internal cut
 * and paste behavior. For instance, Ctrl+K (cut) and Ctrl+Y (paste).
 */

var SecondaryClipboard = {
  cut: function cut(editorState) {
    var content = editorState.getCurrentContent();
    var selection = editorState.getSelection();
    var targetRange = null;

    if (selection.isCollapsed()) {
      var anchorKey = selection.getAnchorKey();
      var blockEnd = content.getBlockForKey(anchorKey).getLength();

      if (blockEnd === selection.getAnchorOffset()) {
        var keyAfter = content.getKeyAfter(anchorKey);

        if (keyAfter == null) {
          return editorState;
        }

        targetRange = selection.set("focusKey", keyAfter).set("focusOffset", 0);
      } else {
        targetRange = selection.set("focusOffset", blockEnd);
      }
    } else {
      targetRange = selection;
    }

    targetRange = nullthrows(targetRange); // TODO: This should actually append to the current state when doing
    // successive ^K commands without any other cursor movement

    clipboard = getContentStateFragment(content, targetRange);
    var afterRemoval = DraftModifier.removeRange(content, targetRange, "forward");

    if (afterRemoval === content) {
      return editorState;
    }

    return EditorState.push(editorState, afterRemoval, "remove-range");
  },
  paste: function paste(editorState) {
    if (!clipboard) {
      return editorState;
    }

    var newContent = DraftModifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), clipboard);
    return EditorState.push(editorState, newContent, "insert-fragment");
  }
};
exports = SecondaryClipboard;
export default exports;