import _DraftRemovableWord from "./DraftRemovableWord";
import _EditorState from "./EditorState";
import _moveSelectionForward from "./moveSelectionForward";
import _removeTextWithStrategy from "./removeTextWithStrategy";
var exports = {};
var DraftRemovableWord = _DraftRemovableWord;
var EditorState = _EditorState;
var moveSelectionForward = _moveSelectionForward;
var removeTextWithStrategy = _removeTextWithStrategy;
/**
 * Delete the word that is right of the cursor, as well as any spaces or
 * punctuation before the word.
 */

function keyCommandDeleteWord(editorState) {
  var afterRemoval = removeTextWithStrategy(editorState, function (strategyState) {
    var selection = strategyState.getSelection();
    var offset = selection.getStartOffset();
    var key = selection.getStartKey();
    var content = strategyState.getCurrentContent();
    var text = content.getBlockForKey(key).getText().slice(offset);
    var toRemove = DraftRemovableWord.getForward(text); // If there are no words in front of the cursor, remove the newline.

    return moveSelectionForward(strategyState, toRemove.length || 1);
  }, "forward");

  if (afterRemoval === editorState.getCurrentContent()) {
    return editorState;
  }

  return EditorState.push(editorState, afterRemoval, "remove-range");
}

exports = keyCommandDeleteWord;
export default exports;