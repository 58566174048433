import _EditorState from "./EditorState";
import _UnicodeUtils from "fbjs/lib/UnicodeUtils";
import _moveSelectionForward from "./moveSelectionForward";
import _removeTextWithStrategy from "./removeTextWithStrategy";
var exports = {};
var EditorState = _EditorState;
var UnicodeUtils = _UnicodeUtils;
var moveSelectionForward = _moveSelectionForward;
var removeTextWithStrategy = _removeTextWithStrategy;
/**
 * Remove the selected range. If the cursor is collapsed, remove the following
 * character. This operation is Unicode-aware, so removing a single character
 * will remove a surrogate pair properly as well.
 */

function keyCommandPlainDelete(editorState) {
  var afterRemoval = removeTextWithStrategy(editorState, function (strategyState) {
    var selection = strategyState.getSelection();
    var content = strategyState.getCurrentContent();
    var key = selection.getAnchorKey();
    var offset = selection.getAnchorOffset();
    var charAhead = content.getBlockForKey(key).getText()[offset];
    return moveSelectionForward(strategyState, charAhead ? UnicodeUtils.getUTF16Length(charAhead, 0) : 1);
  }, "forward");

  if (afterRemoval === editorState.getCurrentContent()) {
    return editorState;
  }

  var selection = editorState.getSelection();
  return EditorState.push(editorState, afterRemoval.set("selectionBefore", selection), selection.isCollapsed() ? "delete-character" : "remove-range");
}

exports = keyCommandPlainDelete;
export default exports;