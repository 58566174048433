import _TokenizeUtil from "fbjs/lib/TokenizeUtil";
var exports = {};
var TokenizeUtil = _TokenizeUtil;
var punctuation = TokenizeUtil.getPunctuation(); // The apostrophe and curly single quotes behave in a curious way: when
// surrounded on both sides by word characters, they behave as word chars; when
// either neighbor is punctuation or an end of the string, they behave as
// punctuation.

var CHAMELEON_CHARS = "['\u2018\u2019]"; // Remove the underscore, which should count as part of the removable word. The
// "chameleon chars" also count as punctuation in this regex.

var WHITESPACE_AND_PUNCTUATION = "\\s|(?![_])" + punctuation;
var DELETE_STRING = "^" + "(?:" + WHITESPACE_AND_PUNCTUATION + ")*" + "(?:" + CHAMELEON_CHARS + "|(?!" + WHITESPACE_AND_PUNCTUATION + ").)*" + "(?:(?!" + WHITESPACE_AND_PUNCTUATION + ").)";
var DELETE_REGEX = new RegExp(DELETE_STRING);
var BACKSPACE_STRING = "(?:(?!" + WHITESPACE_AND_PUNCTUATION + ").)" + "(?:" + CHAMELEON_CHARS + "|(?!" + WHITESPACE_AND_PUNCTUATION + ").)*" + "(?:" + WHITESPACE_AND_PUNCTUATION + ")*" + "$";
var BACKSPACE_REGEX = new RegExp(BACKSPACE_STRING);

function getRemovableWord(text, isBackward) {
  var matches = isBackward ? BACKSPACE_REGEX.exec(text) : DELETE_REGEX.exec(text);
  return matches ? matches[0] : text;
}

var DraftRemovableWord = {
  getBackward: function getBackward(text) {
    return getRemovableWord(text, true);
  },
  getForward: function getForward(text) {
    return getRemovableWord(text, false);
  }
};
exports = DraftRemovableWord;
export default exports;