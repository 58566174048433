import _getFragmentFromSelection from "./getFragmentFromSelection";
var exports = {};
var getFragmentFromSelection = _getFragmentFromSelection;
/**
 * If we have a selection, create a ContentState fragment and store
 * it in our internal clipboard. Subsequent paste events will use this
 * fragment if no external clipboard data is supplied.
 */

function editOnCopy(editor, e) {
  var editorState = editor._latestEditorState;
  var selection = editorState.getSelection(); // No selection, so there's nothing to copy.

  if (selection.isCollapsed()) {
    e.preventDefault();
    return;
  }

  editor.setClipboard(getFragmentFromSelection(editor._latestEditorState));
}

exports = editOnCopy;
export default exports;