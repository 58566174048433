import _DraftJsDebugLogging from "./DraftJsDebugLogging";
import _EditorState from "./EditorState";
import _getContentEditableContainer from "./getContentEditableContainer";
import _getDraftEditorSelection from "./getDraftEditorSelection";
var exports = {};
var DraftJsDebugLogging = _DraftJsDebugLogging;
var EditorState = _EditorState;
var getContentEditableContainer = _getContentEditableContainer;
var getDraftEditorSelection = _getDraftEditorSelection;

function editOnSelect(editor) {
  if (editor._blockSelectEvents || editor._latestEditorState !== editor.props.editorState) {
    if (editor._blockSelectEvents) {
      var _editorState = editor.props.editorState;

      var selectionState = _editorState.getSelection();

      DraftJsDebugLogging.logBlockedSelectionEvent({
        // For now I don't think we need any other info
        anonymizedDom: "N/A",
        extraParams: JSON.stringify({
          stacktrace: new Error().stack
        }),
        selectionState: JSON.stringify(selectionState.toJS())
      });
    }

    return;
  }

  var editorState = editor.props.editorState;
  var documentSelection = getDraftEditorSelection(editorState, getContentEditableContainer(editor));
  var updatedSelectionState = documentSelection.selectionState;

  if (updatedSelectionState !== editorState.getSelection()) {
    if (documentSelection.needsRecovery) {
      editorState = EditorState.forceSelection(editorState, updatedSelectionState);
    } else {
      editorState = EditorState.acceptSelection(editorState, updatedSelectionState);
    }

    editor.update(editorState);
  }
}

exports = editOnSelect;
export default exports;