import _invariant from "fbjs/lib/invariant";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var invariant = _invariant;
var TEXT_CLIPPING_REGEX = /\.textClipping$/;
var TEXT_TYPES = {
  "text/plain": true,
  "text/html": true,
  "text/rtf": true
}; // Somewhat arbitrary upper bound on text size. Let's not lock up the browser.

var TEXT_SIZE_UPPER_BOUND = 5000;
/**
 * Extract the text content from a file list.
 */

function getTextContentFromFiles(files, callback) {
  var readCount = 0;
  var results = [];
  files.forEach(function (
  /*blob*/
  file) {
    readFile(file, function (
    /*string*/
    text) {
      readCount++;
      text && results.push(text.slice(0, TEXT_SIZE_UPPER_BOUND));

      if (readCount == files.length) {
        callback(results.join("\r"));
      }
    });
  });
}
/**
 * todo isaac: Do work to turn html/rtf into a content fragment.
 */


function readFile(file, callback) {
  if (!_global.FileReader || file.type && !(file.type in TEXT_TYPES)) {
    callback("");
    return;
  }

  if (file.type === "") {
    var _contents = ""; // Special-case text clippings, which have an empty type but include
    // `.textClipping` in the file name. `readAsText` results in an empty
    // string for text clippings, so we force the file name to serve
    // as the text value for the file.

    if (TEXT_CLIPPING_REGEX.test(file.name)) {
      _contents = file.name.replace(TEXT_CLIPPING_REGEX, "");
    }

    callback(_contents);
    return;
  }

  var reader = new FileReader();

  reader.onload = function () {
    var result = reader.result;
    !(typeof result === "string") ? invariant(false) : void 0;
    callback(result);
  };

  reader.onerror = function () {
    callback("");
  };

  reader.readAsText(file);
}

exports = getTextContentFromFiles;
export default exports;