import _invariant from "fbjs/lib/invariant";
import _isHTMLElement from "./isHTMLElement";
var exports = {};
var invariant = _invariant;
var isHTMLElement = _isHTMLElement;

function getContentEditableContainer(editor) {
  var editorNode = editor.editorContainer;
  !editorNode ? invariant(false) : void 0;
  !isHTMLElement(editorNode.firstChild) ? invariant(false) : void 0;
  var htmlElement = editorNode.firstChild;
  return htmlElement;
}

exports = getContentEditableContainer;
export default exports;