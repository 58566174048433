import _DraftModifier from "./DraftModifier";
import _EditorState from "./EditorState";
var exports = {};
var DraftModifier = _DraftModifier;
var EditorState = _EditorState;

function keyCommandInsertNewline(editorState) {
  var contentState = DraftModifier.splitBlock(editorState.getCurrentContent(), editorState.getSelection());
  return EditorState.push(editorState, contentState, "split-block");
}

exports = keyCommandInsertNewline;
export default exports;