import _EditorState from "./EditorState";
import _containsNode from "fbjs/lib/containsNode";
import _getActiveElement from "fbjs/lib/getActiveElement";
var exports = {};
var EditorState = _EditorState;
var containsNode = _containsNode;
var getActiveElement = _getActiveElement;

function editOnBlur(editor, e) {
  // In a contentEditable element, when you select a range and then click
  // another active element, this does trigger a `blur` event but will not
  // remove the DOM selection from the contenteditable.
  // This is consistent across all browsers, but we prefer that the editor
  // behave like a textarea, where a `blur` event clears the DOM selection.
  // We therefore force the issue to be certain, checking whether the active
  // element is `body` to force it when blurring occurs within the window (as
  // opposed to clicking to another tab or window).
  var ownerDocument = e.currentTarget.ownerDocument;

  if ( // This ESLint rule conflicts with `sketchy-null-bool` flow check
  // eslint-disable-next-line no-extra-boolean-cast
  !Boolean(editor.props.preserveSelectionOnBlur) && getActiveElement(ownerDocument) === ownerDocument.body) {
    var _selection = ownerDocument.defaultView.getSelection();

    var editorNode = editor.editor;

    if (_selection.rangeCount === 1 && containsNode(editorNode, _selection.anchorNode) && containsNode(editorNode, _selection.focusNode)) {
      _selection.removeAllRanges();
    }
  }

  var editorState = editor._latestEditorState;
  var currentSelection = editorState.getSelection();

  if (!currentSelection.getHasFocus()) {
    return;
  }

  var selection = currentSelection.set("hasFocus", false);
  editor.props.onBlur && editor.props.onBlur(e);
  editor.update(EditorState.acceptSelection(editorState, selection));
}

exports = editOnBlur;
export default exports;