var exports = {};

/**
 * Drag behavior has begun from outside the editor element.
 */
function editOnDragOver(editor, e) {
  editor.setMode("drag");
  e.preventDefault();
}

exports = editOnDragOver;
export default exports;